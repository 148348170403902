import { makeStyles } from "@mui/styles";
import b_img from "../images/b_img.png";
import logo from "../images/logo.png";
import footer from "../images/footer.png";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin ,
} from "react-icons/fa";
import React from "react";
import { Link } from "react-router-dom";
// import { Link } from "@mui/material";

const useStyles = makeStyles({
  containerfluid: {
    width: "100%",
    backgroundColor: "#062F61",
  },
  containerfluid4: {
    width: "100%",
    backgroundImage: `url(${b_img})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  containerfluid1: {
    // width: "100%",
    // backgroundImage: `url(${b_img})`,
    // backgroundSize: "cover",
    // backgroundPosition: "center",
  },
  containerfluid2: {
    width: "100%",
    backgroundColor: "#0F4371",
  },
  containerfluid3: {
    width: "100%",
    // backgroundColor: "#0F4371",
  },
  container: {
    width: "90%",
    margin: "0rem auto",
  },
  subscribe: {
    // display: "flex",
    // justifyContent: "space-between",
    "@media screen and (max-width:1024px)": {
      justifyContent: "left",
    },
  },
  top: {
    minWidth: "min-content",
  },
  main: {
    textAlign: "center",
    "& h1": {
      fontFamily: "none",
    },
  },
  button: {
    backgroundColor: "#ed8c17",
    height: "40px",
    borderRadius: "5px",
    color: "white",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "0px 10px 0px 35px",
    width: "170px",
    fontFamily: "cursive",
    border: "1px white",
  },
  cards: {
    minWidth: "800px",
    display: "flex",
    textAlign: "center",
    // marginTop: "2rem",

    "@media (max-width: 600px)": {
      minWidth: "auto",
      display: "block",
    },
  },
  card: {
    minWidth: "50px",
    width: "500px",
    textAlign: "left",
    padding: "10px 0px 0px 20px",
    // backgroundColor: "#051b36",
    // height: "400px",
    mixBlendMode: "hard-light",
    color: "white",
    "& img": {
      width: "200px",
    },
    "& p": {
      width: "250px",
    },
    "& ul": {
      padding: "0",
    },
    "& ul >li": {
      listStyleType: "none",
      padding: "5px",
      textWrap: "nowrap",
      "@media (max-width: 1250px)": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "200px",
        height: "1.2em",
        whiteSpace: "nowrap",
      },
      "@media (max-width: 950px)": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "160px",
        height: "1.2em",
        whiteSpace: "nowrap",
      },
    },
    "@media (max-width: 600px)": {
      width: "auto",
      // height: "260px",
    },
  },
  card1: {
    minWidth: "50px",
    width: "500px",
    textAlign: "left",
    padding: "10px 0px 0px 20px",
    // backgroundColor: "#051b36",
    // height: "400px",
    mixBlendMode: "hard-light",
    color: "white",
    "& img": {
      width: "200px",
      margin: "1.5rem 0 0.5rem 0",
    },
    "& p": {
      // width: "250px",
    },
    "& ul": {
      padding: "0",
    },
    "& ul >li": {
      listStyleType: "none",
      padding: "5px",
      textWrap: "nowrap",
    },
    "@media (max-width: 600px)": {
      width: "auto",
      // height: "260px",
    },
  },
  main_img: {
    width: "400px",
    "@media screen and (max-width:1140px)": {
      display: "none",
    },
  },
  main_img_div: {
    padding: "10px 0px 5px 80px",
    "@media (max-width: 600px)": {
      padding: "0",
    },
  },
  main_h1_p: {
    marginLeft: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      padding: "1rem",
    },
    "& h1": {
      fontSize: "2.8rem",
      width: "50%",
      color: "white",
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    "& p": {
      color: "white",
      width: "500px",
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
  },
  main_btn: {
    backgroundColor: "white",
    width: "40%",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& button": {
      borderRadius: "inherit",
      backgroundColor: "#FFA826",
      height: "40px",
      width: "90px",
      marginRight: "10px",
    },
    "& input": {
      width: "90%",
      padding: "10px",
      borderRadius: "5px",
      border: "10px solid white",
    },
  },
  footer_side: {
    backgroundColor: "#0F4371",
    display: "flex",
    textAlign: "center",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      minWidth: "auto",
      margin: "0px",
      flexDirection: "column",
    },
    "& ul": {
      display: "flex",
      gap: "10px",
      listStyleType: "none",
      width: "80%",
      padding: "0",
      // justifyContent: "space-around",
      "@media (max-width: 600px)": {
        width: "auto",
        flexDirection: "column",
        padding: "0",
      },
    },
    "& ul >li": {
      color: "white",
      marginRight: "20px",
      display: "flex",
      alignItems: "center",
    },
    "& ul >li>svg": {
      marginRight: "5px",
    },
  },
  footer_side_1: {
    height: "50px",
    // backgroundColor: "#0F4371",
    display: "flex",
    textAlign: "center",
    color: "white",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      minWidth: "auto",
      display: "block",
      margin: "0px",
      height: "300px",
      paddingTop: "1rem",
    },
    "& ul": {
      display: "flex",
      gap: "10px",
      listStyleType: "none",
      margin: "0",
      textWrap: "nowrap",
      alignItems: "center",
      "@media (max-width: 600px)": {
        flexDirection: "column",
        padding: "0",
      },
    },
    "& ul >li": {
      color: "white",
      placeContent: "center",
    },
  },
  link: {
    color: "white",
  },
  link2: {
    textDecoration: "none",
    color: "white",
  },
  followus: {
    display: "flex",
    color: "white",
    width: "15%",
    justifyContent: "space-around",
    alignItems: "center",
    "@media (max-width: 600px)": {
      width: "50%",
    },
  },
});

function Footer() {
  const classes = useStyles();

  return (
    <>
      {/* <div className={classes.containerfluid}>
        <div className={classes.container}>
          <div className={classes.subscribe}>
            <div className={classes.main_h1_p}>
              <h1>Subscribe to get latest job notifications</h1>
              <div className={classes.main_btn}>
                <input type="text" placeholder="Enter your email address" />
                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className={classes.containerfluid4}>
        <div className={classes.containerfluid1}>
          <div className={classes.container}>
            <div className={classes.cards}>
              <div className={classes.card1}>
                <img src={logo} />
                <h3>About Us</h3>
                <p>
                  <b>Hyratch Consultancy Pvt. Ltd</b> is a new-generation
                  startup HR Consultancy. We are an energized team of experts
                  who work together to find the best talent in the market to
                  help companies become more productive and efficient.
                </p>
              </div>
              <div className={classes.card}>
                <h3>Jobs By Industries</h3>
                <ul>
                  <li>Manufacturing</li>
                  <li>Engineering & Construction</li>
                  <li>Automobile/Auto Ancillaries</li>
                  <li>Building Material/Cement/Paint</li>
                  <li>Furnishings / Sanitaryware / Electricals</li>
                  <li>FMCG/F&B</li>
                  <li>Retail</li>
                  <li>Architecture / Interior Design</li>
                  <li>Export-Import / Trading</li>
                  <li>Industrial Design</li>
                </ul>
              </div>
              <div className={classes.card}>
                <h3>Jobs By Industries</h3>
                <ul>
                  <li>Media/Entertainment</li>
                  <li>Metal/Iron/Steel</li>
                  <li>Personal Care/Beauty/Wellness</li>
                  <li>Pharma & Biotech</li>
                  <li>Textile/Garments/Fashion</li>
                  <li>Heat Ventilation/Air conditioning</li>
                  <li>Automation</li>
                  <li>Hospitality</li>
                  <li>Heavy machinery </li>
                  <li>Brewery/beverages</li>
                </ul>
              </div>
              <div className={classes.card}>
                <h3>Links</h3>
                <ul>
                  <li>
                    <Link className={classes.link2} to="/aboutus">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link className={classes.link2} to="/services">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link className={classes.link2} to="/career">
                      Career
                    </Link>
                  </li>
                  {/* <li>
                    <Link className={classes.link2} to="/blogs">
                      Blogs
                    </Link>{" "}
                  </li> */}
                  <li>
                    <Link className={classes.link2} to="/contactus">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.containerfluid2}>
          <div className={classes.container}>
            <div className={classes.footer_side}>
              <ul>
                <li>
                  <FaMapMarkerAlt />
                  119 chatrapati nagar, Indore
                </li>
                <li>
                  <FaEnvelope /> info@hyratch.com
                </li>
                <li>
                  <FaPhone /> Need help? 24/7 +91 9131690629
                </li>
              </ul>
              <div className={classes.followus}>
                <p>Follow Us</p>

                <Link
                  to="https://www.facebook.com/hyratch"
                  target="_blank"
                  className={classes.link}
                >
                  <FaFacebook />
                </Link>

                <Link
                  to="https://www.instagram.com/hyratch_consultancy/"
                  target="_blank"
                  className={classes.link}
                >
                  <FaInstagram />
                </Link>
                <Link
                  to="https://x.com/HyratchL"
                  target="_blank"
                  className={classes.link}
                >
                  <FaTwitter />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/hyratch/"
                  target="_blank"
                  className={classes.link}
                >
                  <FaLinkedin />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.containerfluid3}>
          <div className={classes.container}>
            <div className={classes.footer_side_1}>
              <div>
                @ 2023-24 Hyratch consultancy pvt ltd. All rights Reserved
              </div>
              <div>
                <ul>
                  <li>Terms Of Services |</li>
                  <li>Privacy Policy </li>
                  {/* <li>Cookie Policy</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
