import React, { useEffect, useState } from "react";
import Main from "./main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./js files/Header";
import Footer from "./js files/Footer";
import CommingSoon from "./js files/CommingSoon";
import ContactUs from "./js files/ContactUs";
import AboutUs from "./js files/AboutUs";
import Services from "./js files/Services";
import Careers from "./js files/Careers";
import ScrollToTop from "./scrolltop";
import FindJobs from "./js files/FindJobs";
import Employers from "./js files/Employers";
import Modal from "./js files/Modal";

function App() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleOpen();
  }, []);
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/findjob" element={<FindJobs />} />
          <Route path="/employers" element={<Employers />} />
          <Route path="/services" element={<Services />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/blogs" element={<CommingSoon />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
        <Footer />
        <Modal
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
          setOpen={setOpen}
        />
        <ScrollToTop />
      </BrowserRouter>
    </>
  );
}
export default App;
