import React from "react";
import { makeStyles } from "@mui/styles";
import { Backdrop, Divider, Fade, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Margin } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    zIndex: "1",
    width:'50%',
    border: "2px solid #000",
    position: "relative",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);",
    padding: "16px 10px 24px",
  },
  buttoncont: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom:'10px',
    "& h5": {
      margin: "0",
    },
  },
  button: {
    background: "transparent",
    border: "none",
    color: "blue",
    cursor: "pointer",
  },
  crossbtn: {
    color: "black",
    cursor: "pointer",
  },
}));

export default function TransitionsModal({
  open,
  handleOpen,
  handleClose,
  setOpen,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleKnowMoreClick = () => {
    window.open("https://forms.gle/ENAgEcmMuu9pDADc9", "_blank");
    setOpen(false);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {/* <h2 id="transition-modal-title">Transition modal</h2> */}
            <div className={classes.buttoncont}>
              <h5>Important Notice</h5>
              <button className={classes.crossbtn} onClick={handleClose}>X</button>
            </div>
            <Divider />
            <p id="transition-modal-description">
              <button className={classes.button} onClick={handleKnowMoreClick}>
                Click Here
              </button>{" "}
              to Registered
            </p>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
