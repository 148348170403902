import React, { useState } from "react";

// import SecHead from "../Home/sectionHeading/sectionheading";
import { ReactComponent as PhoneIcon } from "../images/phoneicon.svg";
import { ReactComponent as LocationIcon } from "../images/location_icon.svg";
import { ReactComponent as TelephoneIcon } from "../images/phoneicon1.svg";
import { ReactComponent as EmailIcon } from "../images/email_icon.svg";
import { TfiEmail } from "react-icons/tfi";
import { RiUser3Line } from "react-icons/ri";
import {
  Button,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { postEnquiry } from "../../service/services";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  containerfluid: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    // background: "#FFFBF1",
  },
  container: {
    paddingTop: "2.5rem",
    width: "90%",
    margin: "0 auto",
    "& .MuiInputBase-root": {
      padding: "1rem 1rem",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "Jost",
      fontWeight: "500",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "#fff",
    },
    "& .MuiTextField-root": {
      background: "white",
      borderRadius: "6px",
      boxShadow: "inset 0px 3px 11px 0px rgba(0, 0, 0, 0.17) ",
      marginBottom: "1rem",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "@media screen and (min-width: 320px) and (max-width: 767px)": {
        margin: "8px 0",
      },
      "& .MuiTypography-h6": {
        color: "#252525",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: "Jost",
      },
      "& .MuiFormControlLabel-label": {
        fontFamily: "Jost",
        fontSize: "0.9rem",
        textTransform: "capitalize",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
      "& input:-internal-autofill-selected": {
        backgroundColor: "#fff",
      },
      "& .MuiButton-fullWidth": {
        width: "75%",
        color: "white",
        "@media screen and (min-width: 320px) and (max-width: 767px)": {
          alignSelf: "center",
        },
      },
    },
  },

  content: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  messageform: {
    width: "40%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  inputhead: {
    marginTop: "1rem",
  },
  descriptioninput: {
    "& .MuiInputBase-input": {
      padding: "2.5rem 0",
      fontFamily: "Jost",
      fontWeight: "500",
    },
  },
  bluecustombtn: {
    background: "linear-gradient(90deg, #141E30 0%, #243B55 100%)",
    color: "white",
    marginTop: "2.5rem",
    padding: "0.5rem 1rem",
    borderRadius: "0.4rem",

    "& .MuiButton-label": {
      fontSize: "1rem",
      fontFamily: "Jost",
      fontWeight: "400",
    },
  },
  informationsection: {
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  infocard: {
    background: "#062F61",
    padding: "2rem",
    marginBottom: "1rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    "& .MuiTypography-h3": {
      fontSize: " 1.5rem",
      fontFamily: "Playfair Display",
      fontWeight: "700",
    },
    "& .MuiTypography-h6": {
      fontSize: " 1rem",
      fontFamily: "Jost",
      fontWeight: "400",
      color: "#fff",
    },
  },
  locationicon: {
    border: "2.4px solid #fff",
    borderRadius: "45px",
    padding: "0.7rem 1rem",
    background: "#fff",
  },
  phoneicon: {
    border: "2.4px solid #fff",
    borderRadius: "50px",
    padding: "0.7rem 0.8rem",
    background: "#fff",
  },
  emailicon: {
    border: "2.4px solid #fff",
    borderRadius: "45px",
    padding: "0.9rem 0.7rem",
    background: "#FFF",
  },
  infodata: {
    width: "237.467px",
    color: "white",
    padding: "0.2rem 1.5rem",
  },
  map: {
    marginTop: "3rem",
    border: "0",
    marginBottom: "1rem",
  },
}));

function ContactUs() {
  const classes = useStyles();
  const Heading = [
    {
      id: 1,
      headingsmall: "Deliver Your Message with Precision and Impact",
      headingmain: "Send Your Message",
    },
  ];

  //
  const [formData, setFormData] = useState({
    full_name: "",
    phone: "",
    email: "",
    message: "",
    type: "contactus",
  });

  // handleChange function definition
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Define the handleSubmit function
  //   const handleSubmit = async () => {
  //     try {
  //       const response = await postEnquiry({ enquiry: formData });
  //       setFormData({
  //         full_name: "",
  //         phone: "",
  //         email: "",
  //         message: "",
  //         type: "contactus",
  //       });

  //       toast.success(
  //         response.data.message
  //           ? response.data.message
  //           : "Enquiry submitted successfully!"
  //       );
  //     } catch (error) {
  //       const errorMessage = error.response
  //         ? error.response.data.message
  //         : "An error occurred. Please try again later.";
  //       toast.error(errorMessage);
  //     }
  //   };

  return (
    <div className={classes.containerfluid}>
      <div className={classes.container}>
        {/* <SecHead Heading={Heading} /> */}
        <div className={classes.content}>
          <div className={classes.messageform}>
            <FormControl sx={{ m: 1, width: "25ch" }}>
              <Typography
                className={classes.inputhead}
                variant="h6"
                gutterBottom
              >
                Your Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                id="name"
                type="text"
                placeholder="Enter your Name"
                onChange={handleChange}
                name="full_name"
                value={formData.full_name}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <RiUser3Line />
                    </InputAdornment>
                  ),
                }}
              />

              <Typography
                className={classes.inputhead}
                variant="h6"
                gutterBottom
              >
                Mobile No. <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                id="mobilenumber"
                type="mobilenumber"
                placeholder="+91 8965 458 888"
                // onChange={handleChange}
                name="phone"
                // value={formData.phone}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                className={classes.inputhead}
                variant="h6"
                gutterBottom
              >
                Email Address <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                id="email"
                type="email"
                placeholder="Johndeere43545@gmail.com"
                // onChange={handleChange}
                name="email"
                // value={formData.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TfiEmail />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                className={classes.inputhead}
                variant="h6"
                gutterBottom
              >
                Write a Message <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className={classes.descriptioninput}
                id="message"
                type="text"
                // onChange={handleChange}
                name="message"
                // value={formData.message}
              />

              <Button
                className={classes.bluecustombtn}
                fullWidth
                // onClick={handleSubmit}
              >
                Send Message
              </Button>
            </FormControl>
          </div>
          <div className={classes.informationsection}>
            <div>
              <div className={classes.infocard}>
                <div className={classes.locationicon}>
                  <LocationIcon />
                </div>
                <div className={classes.infodata}>
                  <Typography variant="h3">Our Address</Typography>
                  <Typography variant="h6">
                    119 chatrapati nagar, Indore
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.infocard}>
                <div className={classes.phoneicon}>
                  <TelephoneIcon />
                </div>
                <div className={classes.infodata}>
                  <Typography variant="h3">Our Phone No.</Typography>
                  <Typography variant="h6">(91316-90629)</Typography>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.infocard}>
                <div className={classes.emailicon}>
                  <EmailIcon />
                </div>
                <div className={classes.infodata}>
                  <Typography variant="h3">Email</Typography>
                  <Typography variant="h6">info@hyratch.com</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.104283945018!2d75.83537177785209!3d22.724364974256893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd97b92b0ec3%3A0x3401ba0f92ca2c7b!2sChhatrapati%20Nagar%2C%20Indore%2C%20Madhya%20Pradesh%20452006!5e0!3m2!1sen!2sin!4v1723821773103!5m2!1sen!2sin"
          width="100%"
          height="500"
          title="myFrame"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
export default ContactUs;
